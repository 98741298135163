/** 实验仪器 */
import request from '@/utils/request'

export function getPageData(data) {
  return request({
    url: '/Lab_apparatus/getPageData',
    method: 'post',
    data
  })
}
/**
 * 获取预约数据列表
 * 参数：仪器id，测试项目id，时间区间
 * @param {} data 
 * @returns 
 */
export function getReserveData(data) {
  return request({
    url: '/Lab_ApprovalFormS/getPageData',
    method: 'post',
    data
  })
}

export default {
  getPageData,
  getReserveData
}