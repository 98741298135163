/**基础信息 */
import request from '@/utils/request'

export function getPageData(data) {
  return request({
    url: '/Lab_AppointmentFormP/getPageData',
    method: 'post',
    data
  })
}
export function add(data) {
  return request({
    url: '/Lab_AppointmentFormP/Add',
    method: 'post',
    data
  })
}
export function update(data) {
  return request({
    url: '/Lab_AppointmentFormP/update',
    method: 'post',
    data
  })
}
export function del(data) {
  return request({
    url: '/Lab_AppointmentFormP/del',
    method: 'post',
    data
  })
}

export function getDetailPage(data) {
  return request({
    url: '/Lab_AppointmentFormP/getDetailPage',
    method: 'post',
    data
  })
}
// 下载合同 flag: inset 对内 outset 对外
export function downLoadAgreement(data) {
  let map = {
    inside: 1,
    outside: 2
  };
  return request({
    url: '/ApiAdmin/ContractTemplateDownload',
    method: 'post',
    params: {
      HT_Type: map[data.flag],
      DeptID: data.labId
    },
    responseType: 'blob'
  })
}
export default {
  getPageData,
  add,
  del,
  getDetailPage,
  downLoadAgreement
}
