<template>
  <div class="calendar-wrap" v-loading="loading.show">
    <div class="header">
      <span class="txt-ym">{{ ymTxt }}</span>
      <el-button-group>
        <el-button @click="prev"><i class="el-icon-arrow-left"></i> {{ $t('word.prevMonth') }}</el-button>
        <el-button @click="today">{{ $t('word.curMonth') }}</el-button>
        <el-button @click="next">{{ $t('word.nextMonth') }} <i class="el-icon-arrow-right"></i></el-button>
      </el-button-group>
    </div>
    <div ref="calendar" class="calendar"></div>
  </div>
</template>
<script>
import TuiCalendar from "tui-calendar";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      inst: null,
      curModel: {
        year: 2020,
        month: 1,
        daterange: ['', '']
      }
    };
  },
  computed: {
    ...mapState(['lang']),
    // 年月显示
    ymTxt() {
      let montharr = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
      let res = this.lang === 'zh-CN' ? `${this.curModel.year}年 ${this.curModel.month}月` : this.curModel.year + ' ' + montharr[this.curModel.month - 1];
      return res;
    }
  },
  props: {
    loading: {
      type: Object,
      default() {
        return {
          show: false,
          text: '加载中...'
        }
      }
    },
    scheduleas: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    scheduleas: {
      deep: true,
      handler(val, old) {
        this._updateScheduleas(val);
      }
    }
  },
  methods: {
    init() {
      // console.log(this.$i18n.locale);
      let daynames = [
        this.$t('date.sunday'),
        this.$t('date.monday'),
        this.$t('date.tuesday'),
        this.$t('date.wednesday'),
        this.$t('date.thursday'),
        this.$t('date.friday'),
        this.$t('date.saturday')
      ];
      this.inst = new TuiCalendar(this.$refs.calendar, {
        defaultView: "month",
        isReadOnly: true,
        theme: {
          'month.schedule.height': '18px',
          'month.schedule.marginTop': '4px'
        },
        month: {
          daynames
        },
        template: {
          time: function(schedule) {
            return schedule.title;
          }
        }
      });
      this.inst.createSchedules(this.scheduleas);
      this.inst.on("clickSchedule", event => {
        this.$emit('on-schedule-click', event);
      });
      let today = new Date(this.inst.getDate());
      this.curModel.year = today.getFullYear();
      this.curModel.month = today.getMonth() + 1;
      this.curModel.daterange[0] = this.getDateRangeStart();
      this.curModel.daterange[1] = this.getDateRangeEnd();
    },
    getDate() {
      return this.inst.getDate();
    },
    getDateRangeStart() {
      return this.inst.getDateRangeStart();
    },
    getDateRangeEnd() {
      return this.inst.getDateRangeEnd();
    },
    prev() {
      this.inst.prev();
      if (this.curModel.month === 1) {
        this.curModel.month = 12;
        this.curModel.year--;
      } else {
        this.curModel.month--;
      }
      this.curModel.daterange[0] = this.getDateRangeStart();
      this.curModel.daterange[1] = this.getDateRangeEnd();
      this.$emit('on-month-change', this.curModel);
    },
    next() {
      this.inst.next();
      if (this.curModel.month === 12) {
        this.curModel.month = 1;
        this.curModel.year++;
      } else {
        this.curModel.month++;
      }
      this.curModel.daterange[0] = this.getDateRangeStart();
      this.curModel.daterange[1] = this.getDateRangeEnd();
      this.$emit('on-month-change', this.curModel);
    },
    today() {
      this.inst.today();
      let now = new Date(this.inst.getDate());
      let nowYear = now.getFullYear();
      let nowMonth = now.getMonth() + 1;
      if (nowYear === this.curModel.year && nowMonth === this.curModel.month) {
        return;
      }
      this.curModel.year = nowYear;
      this.curModel.month = nowMonth;
      this.curModel.daterange[0] = this.getDateRangeStart();
      this.curModel.daterange[1] = this.getDateRangeEnd();
      this.$emit('on-month-change', this.curModel);
    },
    _updateScheduleas(schedules) {
      this.inst.clear();
      this.inst.createSchedules(schedules, true);
      this.inst.render();
    }
  },
  mounted() {
    this.init();
  }
}
</script>
<style scoped>
.calendar-wrap{ height: 100%; }
.header{ display: flex; justify-content: space-between; padding: 5px; }
.header .txt-ym{ padding-left: 15px; font-size: 16px; font-weight: bold; }
.calendar{ height: calc(100% - 43px); width: 100%; }
.calendar >>> .tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator{ background-color: #2b85e4; }
.calendar >>> .tui-full-calendar-weekday-schedule-bullet{ display: none; }
.calendar >>> .tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time .tui-full-calendar-weekday-schedule-title{ background-color: #ff9900; color: #fff!important; border-left: 3px solid #515a6e; border-radius: 2px; }
.calendar >>> .tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-more-schedules:hover{ color:#2b85e4; }
</style>
